import style from "../Sidebar/SidebarStyle.module.css";
import {Link, useLocation} from "react-router-dom";
import quitIcon from "../../Images/quitIcon.png";
import React, {useContext} from "react";
import {SidebarContext} from "../../Providers/SidebarProvider";
import {AuthContext} from "../../Providers/AuthProvider";
import {logout} from "../../api";
import diaryIcon from "../../Images/diaryIcon.png";
import scheduleIcon from "../../Images/scheduleIcon.png";
import messageIcon from "../../Images/messageIcon.png";
import {UserContext} from "../../Providers/UserProvider";

function NavigationLink({path, label, icon}) {
    const location = useLocation().pathname;
    return (
        <li className={(location === path) ? `${style.menuLiActive} ${style.listItem}` : `${style.listItem}`}>
            <Link className={style.link} to={path}>
                <img src={"" + icon} style={{marginRight: "15px", alignSelf: "center"}} alt="icon"/>
                {label}
            </Link>
        </li>);
}

function Sidebar() {
    const {token, setAuth} = useContext(AuthContext);
    const {open} = useContext(SidebarContext);
    const [user] = useContext(UserContext);
    return (
        <div className={style.menu} style={{left: (open) ? '0' : '-80vw'}}>
            <h1 style={{color: "white", marginTop: "3rem"}}>Меню:</h1>
            {
                token && user ?
                    <ul className={`${style.menuList} ${style.spis}`}>
                        {user.student ? <NavigationLink icon={diaryIcon} path="/diary" label="Дневник"/> : ""}
                        <NavigationLink icon={scheduleIcon} path="/schedule" label="Расписание"/>
                        {!user.student ? <NavigationLink icon={diaryIcon} path="/journal" label="Журнал"/> : ""}
                        {!user.student ? <NavigationLink icon={diaryIcon} path="/quarters" label="Четверти"/> : ""}
                        <NavigationLink icon={messageIcon} path="№" label="Сообщения"/>
                        <li className={`${style.listItem}`}>
                            <button className={style.link} onClick={() => logout(token, setAuth)} style={{
                                background: "transparent",
                                border: "none",
                                cursor: "pointer"
                            }}>
                                <div style={{alignSelf: "center"}}>
                                    <img src={"" + quitIcon} style={{marginRight: "15px"}} alt=""/>
                                </div>
                                Выйти
                            </button>
                        </li>
                        <hr style={{color: "white"}}/>
                    </ul> : ""
            }
            <ul className={`${style.menuList} ${style.spis}`}>
                <li className={`${style.listItem}`}>
                    <Link className={`${style.link}`} to="https://chastnaya-shkola.ru/nachalnaya-shkola/">
                        ОБРАЗОВАНИЕ
                    </Link>
                </li>
                <li className={`${style.listItem}`}>
                    <Link className={`${style.link}`} to="https://chastnaya-shkola.ru/postupleniye-oplata/">
                        ПОСТУПЛЕНИЕ И ОПЛАТА
                    </Link>
                </li>
                <li className={`${style.listItem}`}>
                    <Link className={`${style.link}`} to="https://chastnaya-shkola.ru/about/">
                        ИНФОРМАЦИЯ
                    </Link>
                </li>
                <li className={`${style.listItem}`}>
                    <Link className={`${style.link}`} to="https://chastnaya-shkola.ru/contact/">
                        КОНТАКТЫ
                    </Link>
                </li>
                <li className={`${style.listItem} ${style.listContactItem}`} style={{flexDirection: "column"}}>
                    <Link to="tel: +7 921 890-29-35" style={{textDecoration: "none", color: "white"}}><p>+7 921 890-29-35</p></Link>
                    <br/>
                    <Link className={`${style.contactLink}`} to="https://chastnaya-shkola.ru/contact/">Обратная
                        связь</Link>
                </li>
            </ul>
        </div>
    );
}

export default Sidebar;