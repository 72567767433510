import React, {useContext, useState} from 'react';
import style from './Calendar.module.css';
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import {ApiInstance} from "../../api";
import {AuthContext} from "../../Providers/AuthProvider";
import {groupBy} from "../Journal/Journal";
import {Navigate} from "react-router-dom";

const DAYS = [
    'Воскресенье',
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
];
const MONTHS = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
]

StudentCalendar.cache = {};//years.months - data

export function StudentCalendar() {
    const {token} = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [chosenDate, setChosenDate] = useState(null);

    if (!token) return <Navigate to="/auth" replace={true}/>;
    if (chosenDate == null) {
        setChosenDate(new Date());
        loadMonth(new Date(Date.now()));
    }

    function loadMonth(dayOfMonth) {
        const normal = getDate(dayOfMonth).toISOString().split('T')[0];
        const date = getDate(dayOfMonth);
        const monthNumber = date.getMonth();
        const yearNumber = date.getFullYear();
        if (StudentCalendar.cache[yearNumber] && StudentCalendar.cache[yearNumber][monthNumber]) return;

        setLoading(true);
        ApiInstance(token).get('/schedule/month?day=' + normal).then(r => {
            let data = r.data;
            data.forEach(item => {
                const startAt = new Date(item.startAt);
                const finishAt = new Date(item.finishAt);
                item.day = startAt.getDate();
                item.title = item.subject.name;
                item.time = `${getStringTime(startAt)} - ${getStringTime(finishAt)}`;
            });
            data.sort((a, b) => new Date(a.startAt) - new Date(b.startAt));
            data = groupBy(data, (item) => item.day);
            Object.keys(data).forEach(day => data[day] = groupBy(data[day], (item) => item.subject.type));
            if (!StudentCalendar.cache[yearNumber]) StudentCalendar.cache[yearNumber] = {};
            StudentCalendar.cache[yearNumber][monthNumber] = data;
            setLoading(false);
        });
    }

    let data = {};
    if (chosenDate != null) {
        const date = getDate(chosenDate);
        const monthNumber = date.getMonth();
        const yearNumber = date.getFullYear();
        const dayNumber = date.getDate();
        if (StudentCalendar.cache[yearNumber] && StudentCalendar.cache[yearNumber][monthNumber]) {
            data = StudentCalendar.cache[yearNumber][monthNumber][dayNumber] ?? {};
        }
    }

    return (
        <div className={style.main}>
            <div style={{position: "relative"}}>
                {loading ?
                    <div style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(255, 255, 255, 0.7)"
                    }}>
                        <div className={style.loader}></div>
                    </div> : ""}
                <Calendar
                    prev2Label={null}
                    next2Label={null}
                    minDetail="year"
                    showNeighboringDecade={false}
                    onClickDay={(value) => setChosenDate(value)}
                    onActiveStartDateChange={(action) => loadMonth(action.activeStartDate)}
                />
            </div>
            <div className={style.description}>
                {chosenDate ?
                    <div className={style.date}>
                        <div className={style.dayNumb}>
                            <h1 style={{fontWeight: 800}}>
                                {String(chosenDate.getDate()).padStart(2, "0")}
                            </h1>
                        </div>
                        <div className={style.dayDesc}>
                            {MONTHS[chosenDate.getMonth()]} {String(chosenDate.getFullYear())} <br/>
                            <span className={style.secondary}>
                            {DAYS[chosenDate.getDay()]}
                        </span>
                        </div>
                    </div> : ""}
                <TopicSchedules data={data[0]} name="Предметы:" absenceLabel="Нет занятий"/>
                <TopicSchedules data={data[1]} name="Мероприятия:" absenceLabel="Нет мероприятий"/>
            </div>
        </div>
    );
}

export function getStringTime(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
}

export function getDate(date) {
    return new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000));
}

function TopicSchedules({data, name, absenceLabel}) {
    return (
        <div>
            <h3 className={style.title}>{name}</h3>
            <ul className={style.list}>
                {data?.map((d) => (
                    <li className={style.item} key={d.id}>
                        <div style={{width: "125px", color: "#6C827F"}}>{d.time}</div>
                        <div style={{width: "125px"}}>{d.title}</div>
                    </li>
                ))}
                {!data || data.length === 0 ? <h3>{absenceLabel}</h3> : ""}
            </ul>
        </div>
    );
}