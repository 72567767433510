import React from 'react';
import style from './BreadcrumbsStyle.module.css';
import {Link, useLocation} from "react-router-dom";

const Breadcrumbs = (props) => {
    const location = useLocation();
    let title = "";
    switch (location.pathname) {
        case "/profile":
            title = "Профиль";
            break;
        case "/schedule":
            title = "Расписание";
            break;
        case "/diary":
            title = "Дневник";
            break;
        case "/diary/statistics":
            title = "Дневник";
            break;
        case "/class":
            title = "Класс";
            break;
    }
    return (
        <div className={style.container}>
            <div>
                <ul className={`${style.list}`}>
                    <li className={`${style.item} ${style.itemLink}`}>
                        <Link className={style.contactLink} to="/">Главная</Link>
                    </li> >>
                    <li className={`${style.secondary} ${style.item}`}>{title}</li>
                </ul>
            </div>
            <div>
                <h1 className={style.title}>{props.title}</h1>
            </div>
        </div>
    );
};

export default Breadcrumbs;