import React, {useState, useContext, useReducer, useEffect} from 'react';
import style from './JournalStyle.module.css';
import leftTriangle from "../../Images/leftTriangle.png";
import rightTriangle from "../../Images/rightTriangle.png";
import {Carousel} from "../Carousel/Carousel";
import fileManageIcon from "../../Images/fileManageIcon.png";
import cancelIcon from "../../Images/cancelIcon.png";
import resetIcon from "../../Images/resetIcon.png";
import {AuthContext} from "../../Providers/AuthProvider";
import {GroupContext} from "../../Providers/GroupProvider";
import {Navigate, useBlocker} from "react-router-dom";
import {SaveActions} from "../Schedule/TeacherSchedule";
import {getNormalWeekDay, StudentDiary} from "../Diary/StudentDiary";
import {getDate} from "../Schedule/StudentCalendar";
import {ApiInstance, baseUrl} from "../../api";
import Swal from "sweetalert2";

export const romanSymbols = [
    "I",
    "II",
    "III",
    "IV",
    "V",
    "VII",
    "VIII",
    "IIX",
    "IX",
    "X",
];

const marks = ['Н', 'НУ', 'ЧУ', 'У', '2', '3', '4', '5'];
const daysNames = [
    'ПН',
    'ВТ',
    'СР',
    'ЧТ',
    'ПТ',
    'СБ',
];

const fullDaysName = [
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
];

Journal.cache = {};//subject-week-day
Journal.subjects = [];
Journal.students = [];
Journal.addedMarks = {};//subject-week-day-student, where (subject-week-day) == lesson
Journal.lessonUpdated = {};

Journal.cacheClear = function () {
    Journal.cache = {};
    Journal.subjects = [];
    Journal.students = [];
    Journal.addedMarks = {};
    Journal.lessonUpdated = {};
}

export function groupBy(list, keyGetter) {
    const map = {};
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map[key];
        if (!collection) {
            map[key] = [item];
        } else {
            collection.push(item);
        }
    });
    return map;
}

export function FormRedirectBlocker(hasChanges) {
    useEffect(() => {
        if (!hasChanges) return;

        function handleOnBeforeUnload(event) {
            event.preventDefault();
            return (event.returnValue = '');
        }

        window.addEventListener('beforeunload', handleOnBeforeUnload, {capture: true});
        return () => {
            window.removeEventListener('beforeunload', handleOnBeforeUnload, {capture: true});
        }
    }, [hasChanges]);

    let blocker = useBlocker(
        ({currentLocation, nextLocation}) => {
            return currentLocation.pathname !== nextLocation.pathname && hasChanges;
        }
    );

    if (blocker.state === "blocked") {
        Swal.fire({
            title: "Вы уверены что хотите перейти?",
            text: "У вас есть несохраненные изменения",
            showCancelButton: true,
            confirmButtonText: 'Перейти',
            cancelButtonText: 'Отменить',
            confirmButtonColor: "rgb(192, 217, 165)",
            customClass: {
                confirmButton: style.modalButton,
                cancelButton: style.modalButton,
            },
        }).then(r => {
            if (r.isConfirmed) {
                blocker.proceed();
            } else {
                blocker.reset();
            }
        });
    }
}

export function Journal({firstWeekDay}) {
    const {token} = useContext(AuthContext);
    const [group] = useContext(GroupContext);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [selectedWeek, setSelectedWeek] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);
    const [hasChanges, setChanges] = useState(false);

    const [subjects, setSubjects] = useState(null);
    const [students, setStudents] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingStatistics, setLoadingStatistics] = useState(false);
    FormRedirectBlocker(hasChanges);

    if (!token) return <Navigate to="/auth" replace={true}/>;
    if (!group) return;

    if (subjects == null) loadSubjects();
    if (students == null) loadStudents();
    if (!subjects) return "";
    const firstWeekDayDate = StudentDiary.getWeekRange(new Date(Date.parse(firstWeekDay))).startDay;
    if (selectedWeek == null && selectedSubject != null) {
        const diff = StudentDiary.weeksDifference(getDate(new Date()), firstWeekDayDate);
        setSelectedWeek(diff);
        loadWeek(selectedSubject, diff);
    }
    if (!students) return "";

    function loadSubjects() {
        if (subjects != null) return;
        setSubjects(false);
        ApiInstance(token).get(`/periods/${group.periodId}/subjects`).then(r => {
            Journal.subjects = r.data.filter(subject => subject.type === 0);
            setSubjects(true);
            if (Journal.subjects.length > 0) {
                setSelectedSubject(0);
            }
        });
    }

    function loadStudents() {
        if (students != null) return;
        setStudents(false);
        ApiInstance(token).get(`/periods/${group.periodId}/students`).then(r => {
            Journal.students = r.data.nominal;
            setStudents(true);
        });
    }

    function loadWeek(subject, week) {
        if (subject == null) return;
        if (Journal.cache[subject] && Journal.cache[subject][week]) return;
        let cd = new Date(firstWeekDayDate);
        const dateDiff = firstWeekDayDate.getDate() + week * 7;
        cd.setDate(dateDiff);
        const normal = getDate(cd).toISOString().split('T')[0];
        setLoading(true);
        ApiInstance(token).get(`/table/subjects/${Journal.subjects[subject].id}?day=${normal}`).then(r => {
            let data = r.data;
            data.forEach(item => item.day = getNormalWeekDay((new Date(item.startAt)).getDay()));
            data.sort((a, b) => new Date(a.startAt) - new Date(b.startAt));
            data = groupBy(data, (item) => item.day);
            Object.keys(data).forEach(day => {
                data[day] = data[day][0];
                data[day].marks = groupBy(data[day].marks, (mark) => mark.studentId);
            });
            if (!Journal.cache[subject]) Journal.cache[subject] = {};
            Journal.cache[subject][week] = data;
            setLoading(false);
        });
    }

    function loadStudentStatistics(studentIndex, subjectIndex) {
        if (subjectIndex == null) return;
        const student = Journal.students[studentIndex];
        if (!student.statistics) student.statistics = {};
        const subject = Journal.subjects[subjectIndex];
        if (student.statistics[subjectIndex]) return;
        setLoadingStatistics(true);
        ApiInstance(token).get(`/students/${student.id}/statistics/${subject.id}`).then(r => {
            setLoadingStatistics(false);
            student.statistics[subjectIndex] = r.data;
        });
    }

    let days = [];
    const dateDiff = firstWeekDayDate.getDate() + selectedWeek * 7;
    for (let i = 0; i < 6; i++) {
        let cd = new Date(firstWeekDayDate);
        cd.setDate(dateDiff + i);
        cd.setHours(23);
        cd.setMinutes(59);
        cd.setSeconds(59);
        days.push({
            index: i,
            name: fullDaysName[i],
            date: String(cd.getFullYear()).padStart(2, "0") + "-" + String(cd.getMonth() + 1).padStart(2, "0") + "-" + String(cd.getDate()).padStart(2, "0"),
            status: cd.getTime() > Date.now()
        })
    }
    let scrollRight = true;
    if (group.period.range[1]) {
        let end = new Date(group.period.range[1]);
        let cd = new Date(firstWeekDayDate);
        cd.setDate(dateDiff + 7);
        cd.setHours(23);
        cd.setMinutes(59);
        cd.setSeconds(59);
        if (cd.getTime() > end.getTime()) scrollRight = false;
    }

    function selectSubject(subject) {
        setSelectedSubject(subject);
        loadWeek(subject, selectedWeek);
        if (selectedStudent != null) {
            loadStudentStatistics(selectedStudent, subject);
        }
    }

    function selectWeek(week) {
        setSelectedWeek(week);
        loadWeek(selectedSubject, week);
    }

    function selectDay(index) {
        setSelectedDay(index);
        setSelectedStudent(null);
    }

    function selectStudent(index) {
        if (selectedSubject == null) return;
        setSelectedStudent(index);
        setSelectedDay(null);
        loadStudentStatistics(index, selectedSubject);
    }

    function save() {
        let requests = [];
        Object.keys(Journal.addedMarks).forEach(subject => {
            Object.keys(Journal.addedMarks[subject]).forEach(week => {
                Object.keys(Journal.addedMarks[subject][week]).forEach(day => {
                    let lesson = Journal.cache[subject][week][day];
                    if (!lesson) return;
                    Object.keys(Journal.addedMarks[subject][week][day]).forEach(studentId => {
                        const mark = Journal.addedMarks[subject][week][day][studentId];
                        const originalMark = lesson.marks[studentId];
                        if (originalMark) {
                            requests.push(ApiInstance(token).delete('/table/mark/' + originalMark[0].id));
                        }
                        if (mark !== -1) {
                            requests.push(ApiInstance(token).post('/table/mark', {
                                studentId,
                                scheduleId: lesson.id,
                                type: mark
                            }));
                        }
                    });
                });
            });
        });
        requests.push(new Promise((resolve, reject) => {
            let updating = [];
            Object.keys(Journal.lessonUpdated).forEach(subject => {
                Object.keys(Journal.lessonUpdated[subject]).forEach(week => {
                    Object.keys(Journal.lessonUpdated[subject][week]).forEach(lesson => {
                        if (Journal.lessonUpdated[subject][week][lesson]) {
                            let data = Journal.lessonUpdated[subject][week][lesson];
                            if (data.fileRemove) data.file = "null";
                            else if (!data.file) delete data.file;
                            updating.push(data);
                        }
                    });
                });
            });
            ApiInstance(token).post(`/schedule/edit/all`, updating, {headers: {"Content-Type": "multipart/form-data"}})
                .then(() => resolve(true))
                .catch(r => reject(r.response));
        }));
        Promise.all(requests).then(r => {
            Journal.addedMarks = {};
            setChanges(false);
            Journal.cache = {};
            Journal.lessonUpdated = {};
            loadWeek(selectedSubject, selectedWeek);
            Journal.students.forEach(student => {
                student.statistics = {};
            });
            if (selectedStudent != null) {
                loadStudentStatistics(selectedStudent, selectedSubject);
            }
            Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000
            }).fire({
                icon: "success",
                title: "Данные успешно сохранены"
            });
        });
    }

    function cancel() {
        Journal.addedMarks = {};
        setChanges(false);
    }

    function update() {
        const changes = Object.keys(Journal.addedMarks).length > 0 || Object.keys(Journal.lessonUpdated).length > 0;
        if (changes !== hasChanges) setChanges(changes);
    }

    function updateMark(studentId, dayIndex, mark) {
        const lesson = Journal.cache[selectedSubject][selectedWeek][dayIndex];
        if (!lesson) return;
        let originalMark = null;
        if (lesson.marks[studentId]) {
            originalMark = lesson.marks[studentId][0].type;
        }
        const changed = (mark !== originalMark);
        if (changed) {
            if (!Journal.addedMarks[selectedSubject]) Journal.addedMarks[selectedSubject] = {};
            if (!Journal.addedMarks[selectedSubject][selectedWeek]) Journal.addedMarks[selectedSubject][selectedWeek] = {};
            if (!Journal.addedMarks[selectedSubject][selectedWeek][dayIndex]) Journal.addedMarks[selectedSubject][selectedWeek][dayIndex] = {};
            Journal.addedMarks[selectedSubject][selectedWeek][dayIndex][studentId] = mark;
            update();
        } else {
            if (!Journal.addedMarks[selectedSubject][selectedWeek][dayIndex]) return;
            delete Journal.addedMarks[selectedSubject][selectedWeek][dayIndex][studentId];
            if (!Journal.addedMarks[selectedSubject][selectedWeek]) return;
            if (Object.keys(Journal.addedMarks[selectedSubject][selectedWeek][dayIndex]).length > 0) return;
            delete Journal.addedMarks[selectedSubject][selectedWeek][dayIndex];
            if (!Journal.addedMarks[selectedSubject]) return;
            if (Object.keys(Journal.addedMarks[selectedSubject][selectedWeek]).length > 0) return;
            delete Journal.addedMarks[selectedSubject][selectedWeek];
            if (Object.keys(Journal.addedMarks[selectedSubject]).length > 0) return;
            delete Journal.addedMarks[selectedSubject];
            update();
        }
    }

    const carouselOptions = [...Array(5).keys()].map(i => {
        const index = selectedSubject + (i - 2);
        if (index >= 0 && Journal.subjects[index]) return {value: index, label: Journal.subjects[index].name};
        return null;
    });

    return (
        <div>
            <div style={{display: "flex", justifyContent: "center"}}>
                {Journal.subjects.length > 0 ?
                    <Carousel option={selectedSubject} selectedLabel={0} selectOption={selectSubject}
                              options={carouselOptions} time={false}/> :
                    <h1>В этом учебном году пока нет предметов</h1>}
            </div>
            <div style={{display: "flex", justifyContent: "center", marginTop: "50px"}}>
                <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                    <div className={style.journal}>
                        <table className={style.table}>
                            <thead>
                            <tr style={{borderRadius: "15px"}}>
                                <td rowSpan="2" style={{borderRadius: "15px 0 0 0"}}>
                                    <b style={{fontWeight: "bold", fontSize: "1.2rem"}}>ФИО</b><br/>учеников
                                </td>
                                <th colSpan={6} style={{borderRadius: "0 15px 0 0"}}>
                                    <WeeksCarousel right={scrollRight} disabled={selectedSubject == null}
                                                   selectedWeek={selectedWeek}
                                                   select={selectWeek}/>
                                </th>
                            </tr>
                            <tr>
                                {!loading ? days.map((day, index) =>
                                        <WeekDay
                                            key={"day_" + index}
                                            subject={selectedSubject}
                                            week={selectedWeek}
                                            dayData={day}
                                            selected={selectedDay === index}
                                            select={() => selectDay(index)}
                                        />
                                    ) :
                                    <th colSpan={6}>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <div className={style.loader}></div>
                                        </div>
                                    </th>
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {Journal.students.map((student, index) =>
                                <Student
                                    key={"student_" + index}
                                    subject={selectedSubject}
                                    week={selectedWeek}
                                    studentData={student}
                                    select={() => selectStudent(index)}
                                    selected={selectedStudent === index}
                                    daysData={days}
                                    update={(dayIndex, mark) => updateMark(student.id, dayIndex, mark)}
                                />
                            )}
                            {Journal.students.length === 0 ? <tr>
                                <td colSpan={7} style={{
                                    borderRadius: "0 0 15px 15px"
                                }}>Нет учеников
                                </td>
                            </tr> : ""}
                            </tbody>
                        </table>
                    </div>
                    <SideMenu
                        subject={selectedSubject}
                        student={selectedStudent}
                        week={selectedWeek}
                        day={selectedDay}
                        days={days}
                        loadingStatistics={loadingStatistics}
                        lessonUpdated={update}/>
                </div>
            </div>
            <div style={{marginTop: "50px"}}>
                <MarkDescriptionGroup index={0} title="Оценки для ввода в журнал 1-го класса:"
                                      items={[
                                          {name: "У", label: "усвоено"},
                                          {name: "ЧУ", label: "частично усвоено"},
                                          {name: "НУ", label: "не усвоено"},
                                          {name: "Н", label: "не присутствовал"}]}/>
                <MarkDescriptionGroup index={1} title="Оценки для ввода в журнал со 2-го класса:"
                                      items={[
                                          {name: "Н", label: "не присутствовал"},
                                          {name: "2", label: "неудовлетворительно"},
                                          {name: "3", label: "удовлетворительно"},
                                          {name: "4", label: "хорошо"},
                                          {name: "5", label: "отлично"}]}/>
            </div>
            <SaveActions hasChanges={hasChanges} save={save} cancel={cancel}/>
        </div>
    );
}

function SideMenu({subject, week, student, day, days, loadingStatistics, lessonUpdated}) {
    if (student != null)
        return <div className={style.sidebar}>
            <StatisticsMenu subject={subject} studentIndex={student} loadingStatistics={loadingStatistics}/>
        </div>
    else if (day != null) {
        return <LessonEditMenu subject={subject} week={week} day={days[day]} update={lessonUpdated}/>;
    }
}

function StatisticsMenu({subject, studentIndex, loadingStatistics}) {
    const student = Journal.students[studentIndex];
    if (loadingStatistics || !student.statistics || !student.statistics[subject]) {
        return <div style={{display: "flex", justifyContent: "center"}}>
            <div className={style.loader}></div>
        </div>;
    }
    const statistics = student.statistics[subject];
    const attendance = statistics.attendance;

    return <div className={style.statistics}>
        <h1>{student.name}</h1>
        <div className={style.horizontalLine}/>
        <div style={{marginTop: "15px", alignContent: "start", textAlign: "left"}}>
            <span style={{fontWeight: 800, fontSize: "1.3rem"}}>Средние оценки по предмету:</span>
            <div style={{marginTop: "15px", minWidth: "300px"}}>
                <ul style={{marginTop: "10px"}}>
                    {
                        statistics.quarters.map((mark, index) =>
                            <li className={style.statisticsItem}>
                                <span>{romanSymbols[index]} четверть</span>
                                <div className={style.statisticsItemDash}>
                                    <div></div>
                                </div>
                                <span>{mark}</span>
                            </li>)
                    }
                </ul>
                <div className={style.statisticsItem}>
                    <span style={{fontWeight: 800, fontSize: "1.3rem", color: "#618935"}}>Итоговая</span>
                    <div className={style.statisticsItemDash}>
                        <div></div>
                    </div>
                    <span style={{fontWeight: 800, fontSize: "1.4rem", color: "#618935"}}>{statistics.period}</span>
                </div>
            </div>
        </div>
        <div className={style.horizontalLine}/>
        <div style={{marginTop: "15px"}}>
            <StatisticsAttendance attendance={attendance.subject} label="Посещаемость предмета:"/>
            <div className={style.horizontalLine}/>
            <StatisticsAttendance attendance={attendance.summary} label="Общая посещаемость:"/>
        </div>
    </div>;
}

function StatisticsAttendance({attendance, label}) {
    function getAttendanceString(attendance) {
        return `${attendance[0]} из ${attendance[1]}`;
    }

    return <div style={{display: "flex", justifyContent: "space-between", lineHeight: "1.5rem"}}>
        <small style={{textAlign: "left", fontSize: "1.1rem"}}>{label}</small>
        <span style={{
            textAlign: "right",
            fontWeight: 800,
            fontSize: "1.3rem",
            textWrap: "nowrap"
        }}>{getAttendanceString(attendance)}</span>
    </div>;
}

function LessonEditMenu({subject, week, day, update}) {
    const lesson = Journal.cache[subject] && Journal.cache[subject][week] ? Journal.cache[subject][week][day.index] : null;
    if (!lesson) return <div className={style.sidebar} style={{justifyContent: "center"}}><h2>В этот
        день нет урока</h2><p>Выберите другой день</p></div>;

    function updatedValue(key, value) {
        if (value === '') value = null;
        if (!Journal.lessonUpdated[subject]) Journal.lessonUpdated[subject] = {};
        if (!Journal.lessonUpdated[subject][week]) Journal.lessonUpdated[subject][week] = {};
        if (!Journal.lessonUpdated[subject][week][day.index]) Journal.lessonUpdated[subject][week][day.index] = {"id": lesson.id};
        const changed = lesson[key] !== value;
        if (changed) {
            Journal.lessonUpdated[subject][week][day.index][key] = value;
        } else {
            delete Journal.lessonUpdated[subject][week][day.index][key];
            if (Object.keys(Journal.lessonUpdated[subject][week][day.index]).length === 1) delete Journal.lessonUpdated[subject][week][day.index];
            if (Object.keys(Journal.lessonUpdated[subject][week]).length === 0) delete Journal.lessonUpdated[subject][week];
            if (Object.keys(Journal.lessonUpdated[subject]).length === 0) delete Journal.lessonUpdated[subject];
        }
        update();
    }

    return <div className={style.sidebar}>
        <div style={{width: "60%", textAlign: "center", minWidth: "300px"}}>
            <h2 style={{
                marginTop: "10px",
                fontWeight: 800
            }}>{day.name} <span style={{fontWeight: 400}}>{day.date}</span>
            </h2>
            <LessonEditField
                label="Тема урока:"
                placeholder="Напишите здесь тему урока, которую проходили в данный день"
                value={lesson.theme}
                update={(value) => updatedValue("theme", value)}/>
            <LessonEditField
                label="Домашнее задание:"
                placeholder="Введите домашнее задание, которое было задано к уроку в данный день"
                value={lesson.homeTask}
                update={(value) => updatedValue("homeTask", value)}/>
            <LessonEditField
                label="Комментарий урока:"
                placeholder="Напишите здесь комментарий об уроке"
                value={lesson.comment}
                update={(value) => updatedValue("comment", value)}/>
            <FileSelect value={lesson.file} update={(value) => updatedValue("file", value)}/>
        </div>
    </div>;
}

function FileSelect({update, value}) {
    const [name, setName] = useState(null);
    const [removed, setRemoved] = useState(false);

    function select(e) {
        if (e.target.files.length > 0) setName(e.target.files[0].name);
        update(e.target.files[0]);
    }

    function remove() {
        Swal.fire({
            title: "Подтвердите удаление файла",
            showCancelButton: true,
            confirmButtonText: 'Удалить',
            cancelButtonText: 'Отменить',
            confirmButtonColor: "rgb(192, 217, 165)",
            customClass: {
                confirmButton: style.modalButton,
                cancelButton: style.modalButton,
            },
        }).then(r => {
            if (r.isConfirmed) {
                if (value) {
                    setRemoved(true);
                    update("null");
                } else {
                    update(null);
                }
                setName(null);
            }
        })
    }

    function restore() {
        setRemoved(false);
        update(null);
    }

    function buttonAction() {
        if (removed) restore();
        else remove();
    }

    return <div className={style.window} style={{minHeight: "unset", background: removed ? "#fdd" : null}}>
        <div style={{padding: "5px 0"}}>
            <label style={{
                border: "none",
                background: "none",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%"
            }}>
                <input type="file" onChange={select} style={{
                    position: "absolute",
                    zIndex: -1,
                    opacity: 0,
                }}/>
                <img src={"" + fileManageIcon} style={{width: "15px"}} alt=""/>
                <span style={{marginLeft: "5px"}}>{value ? "заменить файл" : "прикрепить файл"}</span>
            </label>
            <div style={{display: "flex", justifyContent: "center"}}>
                {name ? <p style={{wordBreak: "break-all"}}>{name}</p> : value ?
                    <a style={{wordBreak: "break-all"}} href={baseUrl + "/storage/" + value.path}>{value.name}</a>
                    : ""}
                {name || value ? <img
                    onClick={buttonAction}
                    src={"" + (removed ? resetIcon : cancelIcon)} style={{
                    width: "15px",
                    height: "15px",
                    alignSelf: "center",
                    marginLeft: "5px",
                    cursor: "pointer"
                }} alt=""/> : ""}
            </div>
        </div>
    </div>;
}

function LessonEditField({label, placeholder, value, update}) {
    return <div className={style.window}>
        <div className={style.windowHead}>
            <h3>{label}</h3>
        </div>
        <div className={style.windowBody}>
            <div
                contentEditable="true"
                className={style.textarea}
                placeholder={placeholder}
                suppressContentEditableWarning={true}
                onInput={(e) => update(e.currentTarget.textContent)}>
                {value}
            </div>
        </div>
    </div>;
}

function WeekDay({select, selected, subject, week, dayData}) {
    const lesson = Journal.cache[subject] && Journal.cache[subject][week] ? Journal.cache[subject][week][dayData.index] : null;
    const active = dayData.status && lesson;
    return <th className={active ? style.activeDate : style.secondaryDate}
               style={selected ? {
                   outline: "3px solid #9cb967",
                   outlineOffset: "-3px",
                   padding: "10px 0"
               } : {padding: "10px 0"}}
               onClick={active ? () => select() : () => 0}>
            <span style={{fontWeight: 200, userSelect: "none"}}>
                <b style={{
                    fontWeight: 800,
                    fontSize: ".8rem",
                }}>{daysNames[dayData.index]}</b>
                <br/>{dayData.date}
            </span>
    </th>;
}

function WeeksCarousel({selectedWeek, select, right = true, disabled = false}) {
    function action() {
        if (selectedWeek > 0) {
            select(selectedWeek - 1);
        }
    }

    return <div style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 25px",
        alignItems: "center"
    }}>
        <button className={style.weekBtn} onClick={action} style={selectedWeek === 0 || disabled ? {opacity: 0} : {}}>
            <img src={"" + leftTriangle} alt=""/>
        </button>
        <span style={{
            marginLeft: "15px",
            fontSize: "1.2rem",
            fontWeight: 800,
            alignSelf: "center"
        }}>{selectedWeek + 1} неделя</span>
        <button style={disabled || !right ? {opacity: 0, marginLeft: "15px"} : {marginLeft: "15px"}}
                className={style.weekBtn} onClick={() => right ? select(selectedWeek + 1) : 0}>
            <img src={"" + rightTriangle} alt=""/>
        </button>
    </div>;
}

function Student({studentData, select, selected, subject, week, daysData, update}) {
    return <tr className={style.tbody}>
        <td style={selected ? {outline: "3px solid #9cb967", outlineOffset: "-3px"} : {}}
            className={style.student}>
            <button style={{
                background: "none",
                border: "none",
                height: "100%",
                width: "100%",
                fontSize: "1.4rem",
                padding: "5px 15px"
            }} onClick={select}>
                {studentData.name}
            </button>
        </td>
        {daysData.map((dayData, index) => <StudentDay
            key={`student_${studentData.id}_day_${index}`}
            dayData={dayData} subject={subject} week={week}
            studentId={studentData.id}
            update={(mark) => update(index, mark)}/>)}
    </tr>;
}

function StudentDay({dayData, studentId, subject, week, update}) {
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const lesson = Journal.cache[subject] && Journal.cache[subject][week] ? Journal.cache[subject][week][dayData.index] : null;
    if (!lesson) return <td></td>;

    function changeValue(e) {
        const value = e.target.value;
        let mark = parseInt(value);
        update(mark);
        forceUpdate();
    }

    const updated = Journal.addedMarks[subject] &&
        Journal.addedMarks[subject][week] &&
        Journal.addedMarks[subject][week][dayData.index] &&
        Journal.addedMarks[subject][week][dayData.index][studentId] != null;

    function getMark() {
        if (updated) {
            return Journal.addedMarks[subject][week][dayData.index][studentId];
        } else {
            const studentMarks = lesson.marks[studentId];
            if (studentMarks) return studentMarks[0].type;
        }
        return null;
    }

    const mark = getMark();

    if (dayData.status) {
        return <td style={updated ? {background: "#dfd", padding: 0} : {padding: 0}}>
            <select className={style.select} onChange={changeValue} value={mark ?? -1}>
                <option value="-1">-</option>
                {marks.map((m, index) => <option value={index} key={`value_${index}`}>{m}</option>)}
            </select>
        </td>;
    } else {
        return <td></td>;
    }
}

function MarkDescriptionGroup({index, title, items}) {
    return (<div style={{textAlign: "center", marginTop: '3rem'}}>
        <h1 style={{fontWeight: 800}}>{title}</h1>
        <div style={{display: "flex", justifyContent: "space-evenly", marginTop: '30px'}}>
            {items.map((item, i) => <MarkDescription key={`group_${index}_${i}`} name={item.name} label={item.label}/>)}
        </div>
    </div>);
}

function MarkDescription({name, label}) {
    return (<div style={{padding: "0 15px"}}>
        <h1 style={{color: "#6f8b3e", fontWeight: 800, fontSize: "2.7rem"}}>{name}</h1>
        <span>{label}</span>
    </div>);
}